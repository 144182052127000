import { Injectable } from '@angular/core';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  private audioObjs = {};
  private sounds = {
    'good': 'assets/audio/good.mp3',
    'bad': 'assets/audio/bad.mp3'
  }

  constructor(private eventsService: EventsService) { 
    //get them all ready to play
    for (const sound in this.sounds) {
      this.audioObjs[sound] = new Audio();
      this.audioObjs[sound].autoplay = false;
      this.audioObjs[sound].controls = false;
      this.audioObjs[sound].preload = 'auto';
      this.audioObjs[sound].src = this.sounds[sound];
      this.audioObjs[sound].volume = 0.5;

      //supposed to be good on iOs as no preload?
      //this.audioObjs[sound].load();
    }

    eventsService.subscribe('mute', val => {
      this.mute(val.muted)
    })
  }

  play(sound) {
    if (this.audioObjs[sound]) {
      //this.audioObjs[sound].currentTime = 0;
      this.audioObjs[sound].play();
    }
  }

  mute(mute) {
    for (const obj in this.audioObjs)
  	  this.audioObjs[obj].muted = mute;
  }

  //dispose?
}
