import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

	//currently only for one thing
	//what should be if not reqd/already asked?
	//Don;t seem to be able t get state so not that useful... persist?
	state:string;

  constructor() { }

  async requestMotion() {
  	try {
		let DeviceMotionEventObj = (DeviceMotionEvent as any);
		if (DeviceMotionEventObj && (typeof DeviceMotionEventObj.requestPermission === 'function')) {
    		this.state = await DeviceMotionEventObj.requestPermission();
    	}
	}
	catch (e) {
	    console.error(e);
	}
  }

  get motionGranted() {
  	return this.state == 'granted';
  }
}
